import {
  Box,
  FormControl,
  FormControlLabel,
  Switch,
  Tooltip,
} from "@mui/material"
import type { FieldProps, BooleanFieldSchema } from "@w3rone/json-schema-form"
import { FormErrors } from "./FormErrors"
import { Info } from "@mui/icons-material"

export const CheckboxField = ({
  value: valueProps,
  onChange,
  name,
  label,
  errors,
  description,
  schema,
}: FieldProps<BooleanFieldSchema>) => {
  const value = Boolean(valueProps)

  return (
    <div>
      <FormControl error={errors.length > 0}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControlLabel
            control={
              <Switch
                disabled={schema.readOnly}
                name={name}
                checked={value}
                onChange={(e) => onChange?.(e.target.checked)}
              />
            }
            label={label}
          />
          {description ? (
            <Tooltip
              title={<div dangerouslySetInnerHTML={{ __html: description }} />}
            >
              <Info />
            </Tooltip>
          ) : null}
        </Box>
        <FormErrors errors={errors} />
        {schema.readOnly && value ? (
          <input type="hidden" name={name} value="on" />
        ) : null}
      </FormControl>
    </div>
  )
}
