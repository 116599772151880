import type {
  FieldProps,
  MultipleChoiceFieldSchema,
  SimpleChoiceFieldSchema,
} from "@w3rone/json-schema-form"
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material"
import { FormErrors } from "./FormErrors"
import { Option, Select, SelectProps } from "app/components/Select"
import * as R from "remeda"
import * as React from "react"
import { MaterialIcon } from "./MaterialIcon"
import { Info, KeyboardArrowDown } from "@mui/icons-material"

export const ChoiceField = (
  props: FieldProps<SimpleChoiceFieldSchema | MultipleChoiceFieldSchema>,
) => {
  if (props.schema.type === "array") {
    return (
      <MultipleChoiceField
        {...(props as FieldProps<MultipleChoiceFieldSchema>)}
      />
    )
  } else {
    return (
      <SimpleChoiceField {...(props as FieldProps<SimpleChoiceFieldSchema>)} />
    )
  }
}

const SimpleChoiceField = ({
  schema,
  errors,
  label,
  description,
  name,
  value,
  onChange,
  placeholder,
  id,
  defaultValue,
  required,
}: FieldProps<SimpleChoiceFieldSchema>) => {
  const options = getOptions(
    schema.enum,
    schema.options.choice.enumTitles,
    schema.options.choice.preferredChoices,
  )

  if (schema.options.choice.expanded) {
    return (
      <FormControl
        component="fieldset"
        variant="standard"
        error={errors.length > 0}
        fullWidth={true}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <FormLabel component="legend">{label}</FormLabel>
          {description ? (
            <Tooltip
              title={<div dangerouslySetInnerHTML={{ __html: description }} />}
            >
              <Info />
            </Tooltip>
          ) : null}
        </Box>
        <FormErrors errors={errors} />
        <RadioGroup
          aria-label={label}
          name={name}
          value={value}
          onChange={(e) => onChange?.(e.target.value)}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              disabled={schema.readOnly && option.value !== value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    )
  }

  return (
    <Select
      options={options}
      value={
        getSelectedOptions(
          options,
          value ? String(value) : String(defaultValue),
        )[0] || null
      }
      onChange={(_event, selectedOption) => {
        if (Array.isArray(selectedOption)) {
          throw new Error("Array value given to non multiple Select")
        }

        onChange?.(selectedOption ? selectedOption.value : "")
      }}
      multiple={false}
      name={name}
      label={label}
      errors={errors}
      description={description}
      placeholder={placeholder}
      id={id}
      isNullable={!required}
      disabled={schema.readOnly}
      popupIcon={
        schema.options.attr?.icon ? (
          <MaterialIcon icon={schema.options.attr.icon.toLowerCase()} />
        ) : (
          <KeyboardArrowDown fontSize="large" />
        )
      }
    />
  )
}

const getSelectedOptions = (
  options: SelectProps["options"],
  value: string | Array<string>,
) => {
  return options.filter((option) => {
    if (Array.isArray(value)) {
      return value.includes(option.value)
    }

    return option.value === value
  })
}

const MultipleChoiceField = ({
  schema,
  errors,
  label,
  description,
  name,
  value: valueProps,
  onChange,
  placeholder,
  id,
}: FieldProps<MultipleChoiceFieldSchema>) => {
  const options = getOptions(
    schema.items.enum,
    schema.options.choice.enumTitles,
    schema.options.choice.preferredChoices,
  )

  const value = Array.isArray(valueProps) ? valueProps : []

  if (schema.options.choice.expanded) {
    const layout = schema.options.layout ?? "column"

    return (
      <FormControl
        component="fieldset"
        variant="standard"
        error={errors.length > 0}
        fullWidth={true}
      >
        <FormLabel component="legend">{label}</FormLabel>
        {description ? <FormHelperText>{description}</FormHelperText> : null}
        <FormErrors errors={errors} />
        <FormGroup
          sx={{
            flexDirection: layout,
          }}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              disabled={schema.readOnly && !value.includes(option.value)}
              control={
                <Checkbox
                  checked={value.includes(option.value)}
                  name={`${name}[]`}
                  onChange={(e) =>
                    schema.readOnly && value.includes(option.value)
                      ? R.doNothing
                      : e.target.checked
                        ? onChange?.([...value, option.value])
                        : onChange?.(value.filter((v) => v !== option.value))
                  }
                />
              }
              label={option.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    )
  }

  return (
    <Select
      options={options}
      value={getSelectedOptions(options, value)}
      onChange={(_event, selectedOptions) => {
        if (selectedOptions === null) {
          return onChange?.([])
        }

        if (!Array.isArray(selectedOptions)) {
          throw new Error("Non array value given to multiple Select")
        }

        onChange?.(selectedOptions.map((option) => option.value))
      }}
      multiple={true}
      name={name}
      label={label}
      errors={errors}
      description={description}
      placeholder={placeholder}
      id={id}
      disabled={schema.readOnly}
      renderTags={renderTags}
      popupIcon={
        schema?.options.attr?.icon ? (
          <MaterialIcon icon={schema.options.attr.icon.toLowerCase()} />
        ) : (
          <KeyboardArrowDown fontSize="large" />
        )
      }
    />
  )
}

export const getOptions = (
  values: Array<string>,
  labels: Array<string>,
  preferredValues?: Array<string>,
) => {
  const options = values.map((value, index) => ({
    value: String(value),
    label: String(labels[index]),
    preferred: preferredValues?.includes(value) ?? false,
  }))

  const sortedOptions = R.sortBy(options, (option) => !option.preferred)

  return sortedOptions
}

const renderTags = (value: Array<Option>) => {
  return (
    <Typography>
      <strong>{value.length}</strong> éléments sélectionnés
    </Typography>
  )
}
