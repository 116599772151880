import { FieldProps, ObjectFieldSchema } from "@w3rone/json-schema-form"
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import {
  ContentPaste,
  Delete,
  Download,
  Info,
  Preview,
} from "@mui/icons-material"
import { FormErrors } from "./FormErrors"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import { ReactNode } from "react"
import { match } from "ts-pattern"
import { useSnackbar } from "notistack"

export const FileField = ({
  id,
  name,
  label,
  description,
  errors,
  value,
  schema,
}: FieldProps<ObjectFieldSchema>) => {
  assertFileValue(value)

  const [file, setFile] = useState<FileValue | null>(
    value ? getFileValueFromURL(value) : null,
  )

  const [base64, setBase64] = useState<string | null>(null)

  /**
   * This key is used to force file input rerender when the user
   * wants to delete the selected file. Since file inputs can't be
   * resetted, we force to render a new empty one in this case
   */
  const [key, setKey] = useState(Date.now())

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.currentTarget.files?.[0]

    if (!selectedFile) {
      return
    }

    if (file) {
      URL.revokeObjectURL(file.url)
    }

    const url = URL.createObjectURL(selectedFile)
    const type = mimeTypeToFileType(selectedFile.type)

    setFile({ url, type, name: selectedFile.name })
  }

  const handleRemove = () => {
    setFile(null)
    setKey(Date.now())
  }

  const deleteCheckboxRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!deleteCheckboxRef.current) {
      return
    }

    deleteCheckboxRef.current.checked = file === null
  }, [file])

  const handlePaste = (file: Blob) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = () => {
      const fileAsBase64 = fileReader.result as string

      setBase64(fileAsBase64)

      setFile({
        url: fileAsBase64,
        type: mimeTypeToFileType(file.type),
        name: "file-from-clipboard",
      })
    }
  }

  return (
    <FormControl
      variant="standard"
      component="fieldset"
      error={errors.length > 0}
      fullWidth={true}
    >
      {label ? (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <FormLabel component="legend">{label}</FormLabel>
          {description ? (
            <Tooltip
              title={<div dangerouslySetInnerHTML={{ __html: description }} />}
            >
              <Info />
            </Tooltip>
          ) : null}
        </Box>
      ) : null}
      <Input
        id={id}
        name={`${name}[file]`}
        type="file"
        onChange={handleChange}
        key={key}
      />
      {schema.properties.delete ? (
        <input
          style={{ display: "none" }}
          name={`${name}[delete]`}
          type="checkbox"
          ref={deleteCheckboxRef}
        />
      ) : null}

      {/*
      We can't use an AutoField because it would get its value from the form
      context. But in the form context, the value for the file field is a
      string (the original URL of the image), not an object with a potential
      base64 property. So when the AutoField will try to get the value from the
      context, it will fail. This is a problem caused by the fact that we get
      an URL from the server for initial value. So we have two potential value
      shapes : a simple string for initial value, and a { file, base64, ... }
      object for subsequent values
      */}
      <input type={"hidden"} value={base64 || ""} name={`${name}[base64]`} />

      {file ? (
        <FilePreview
          file={file}
          uploadButton={
            schema.readOnly ? null : (
              <label htmlFor={id}>
                <IconButton component="span">
                  <Download />
                </IconButton>
              </label>
            )
          }
          removeButton={
            schema.properties.delete ? (
              <IconButton type={"button"} onClick={handleRemove}>
                <Delete />
              </IconButton>
            ) : null
          }
        />
      ) : (
        <Box>
          <label
            htmlFor={id}
            style={{
              pointerEvents: schema.readOnly ? "none" : undefined,
            }}
          >
            <Button
              variant="text"
              component="span"
              startIcon={<Download />}
              disabled={schema.readOnly}
            >
              Sélectionner un fichier
            </Button>
          </label>
          <ClipboardButton onPaste={handlePaste} />
        </Box>
      )}
      <FormErrors errors={errors} />
    </FormControl>
  )
}

const Input = styled("input")({
  display: "none",
})

function assertFileValue(
  value: any,
): asserts value is string | undefined | null {
  if (value && typeof value !== "string") {
    console.log(value)
    throw new Error("value is not a file value (see previous logged value)")
  }
}

type FilePreviewProps = {
  file: FileValue
  uploadButton: ReactNode
  removeButton: ReactNode
}

const FilePreview = ({
  file,
  uploadButton,
  removeButton,
}: FilePreviewProps) => {
  const PreviewComponent = match(file)
    .with({ type: "image" }, () => ImagePreview)
    .with({ type: "video" }, () => VideoPreview)
    .with({ type: "blob" }, () => BlobPreview)
    .exhaustive()

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <PreviewComponent
        file={file}
        uploadButton={uploadButton}
        removeButton={removeButton}
      />
    </Box>
  )
}

const ImagePreview = (props: FilePreviewProps) => {
  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "start" }}>
      <Box sx={{ flexGrow: 1, maxWidth: 300, maxHeight: 300 }}>
        <img
          src={props.file.url}
          alt={""}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            objectPosition: "center",
          }}
        />
      </Box>
      <Box sx={{ display: "grid", gap: 1 }}>
        {props.uploadButton}
        {props.removeButton}
      </Box>
    </Box>
  )
}

const VideoPreview = (props: FilePreviewProps) => {
  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "start" }}>
      <Box sx={{ flexGrow: 1, maxWidth: 300, maxHeight: 300 }}>
        <video style={{ width: "100%" }}>
          <source src={props.file.url} />
        </video>
      </Box>
      <Box sx={{ display: "grid", gap: 1 }}>
        {props.uploadButton}
        {props.removeButton}
      </Box>
    </Box>
  )
}

const BlobPreview = (props: FilePreviewProps) => {
  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      <Typography
        sx={{
          textTransform: "uppercase",
          minWidth: 0,
          WebkitLineClamp: 1,
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
        }}
      >
        {props.file.name}
      </Typography>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <IconButton href={props.file.url} sx={{ ml: 1 }} target="_blank">
          <Preview />
        </IconButton>
        {props.uploadButton}
        {props.removeButton}
      </Box>
    </Box>
  )
}

type FileValue = {
  url: string
  name: string
  type: FileType
}

type FileType = "image" | "video" | "blob"

export const getFileValueFromURL = (url: string) => {
  return {
    url,
    name: url.split("/").slice(-1)[0] || "",
    type: getFileTypeFromURL(url),
  }
}

const getFileTypeFromURL = (url: string) => {
  const [extension = ""] = url.split(".").slice(-1)

  return extensionToFileType(extension)
}

const extensionToFileType = (extension: string): FileType => {
  const mapping: Record<string, FileType> = {
    jpg: "image",
    jpeg: "image",
    png: "image",
    gif: "image",
    bmp: "image",
    avi: "video",
    mp4: "video",
  }

  const fileType = mapping[extension]

  return fileType || "blob"
}

export const mimeTypeToFileType = (mimeType: string) => {
  const [type] = mimeType.split("/")

  if (type === "image" || type === "video") {
    return type
  }

  return "blob"
}

type ClipBoardButtonProps = {
  onPaste: (file: Blob) => void
}

const ClipboardButton = (props: ClipBoardButtonProps) => {
  const snackbar = useSnackbar()

  const handleClick = async () => {
    try {
      const file = await getFileFromClipboard()
      props.onPaste(file)
    } catch (err) {
      console.error(err)
      let message = "Une erreur est survenue"

      if (err instanceof DOMException) {
        message = "Vous avez refusé l'accès au presse-papier"
      }

      if (err instanceof ClipboardEmptyError) {
        message = "Votre presse-papier est vide"
      }

      if (err instanceof NoValidFileError) {
        message = "Votre presse-papier ne contient pas de fichier valide"
      }

      snackbar.enqueueSnackbar(message, {
        variant: "error",
      })
    }
  }

  return (
    <Button type={"button"} startIcon={<ContentPaste />} onClick={handleClick}>
      Coller à partir du presse-papier
    </Button>
  )
}

const getFileFromClipboard = async () => {
  const clipboardItems = await navigator.clipboard.read()

  const [item] = clipboardItems

  if (!item) {
    throw new ClipboardEmptyError()
  }

  const validType = item.types.find(isValidType)

  if (validType === undefined) {
    throw new NoValidFileError()
  }

  const blob = await item.getType(validType)

  return blob
}

const isValidType = (type: string) => {
  return type.startsWith("image/")
}

class ClipboardEmptyError extends Error {
  constructor(message?: string) {
    super(message)
    this.name = "ClipboardEmptyError"
  }
}

class NoValidFileError extends Error {
  constructor(message?: string) {
    super(message)
    this.name = "NoValidFileError"
  }
}
